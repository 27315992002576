import profileImage from "./assets/images/netvalidx.png";
const logotext = "Netvalidx";
const meta = {
    title: "Netvalidx",
    description: "I’m Alif Fahrizal, a passionate Node Operator and tech enthusiast, committed to building resilient and decentralized networks.",
};

const introdata = {
    title: "Hello, Netvalidx Here...",
    animated: {
        first: "I secure blockchain networks",
        second: "I build decentralized systems",
        third: "I contribute to Web3 development",
    },
    description: "As a dedicated Node Operator, I focus on maintaining network integrity and enhancing blockchain reliability for a decentralized future.",
    your_img_url: profileImage,
};

const dataabout = {
    title: "A bit about me",
    aboutme: "With expertise in node operations and decentralized technologies, I ensure robust and secure systems. My passion lies in advancing the Web3 ecosystem and driving innovation through collaboration.",
};

const worktimeline = [
    {
        jobtitle: "Node Operator",
        where: "Ethereum Network",
        date: "2021 - Present",
    },
    {
        jobtitle: "Blockchain Developer",
        where: "Web3 Solutions",
        date: "2019 - 2021",
    },
    {
        jobtitle: "Software Engineer",
        where: "Tech Innovators",
        date: "2017 - 2019",
    },
];

const skills = [
    {
        name: "Node.js",
        value: 65,
    },
    {
        name: "Blockchain Protocols",
        value: 75,
    },
    {
        name: "Smart Contracts",
        value: 60,
    },
    {
        name: "Linux Server Management",
        value: 65,
    },
    {
        name: "Networking",
        value: 50,
    },
];

const services = [
    {
        title: "Node Operations",
        description: "Ensuring the security, performance, and uptime of blockchain nodes on major networks.",
    },
    {
        title: "Blockchain Development",
        description: "Creating and deploying smart contracts and decentralized applications.",
    },
    {
        title: "Infrastructure Consulting",
        description: "Helping projects design and implement scalable and secure blockchain infrastructure.",
    },
];

const dataportfolio = [
    {
        img: "https://cryptologos.cc/logos/covalent-cqt-logo.png",
        description: "COVALENT : The Block Results Producer is a concurrent Block Specimen data processing framework designed for scalable and verified data transformations. The output, one of many possible, is a Block Result;",
        link: "https://www.covalenthq.com/staking/",
    },
    {
        img: "https://pbs.twimg.com/profile_images/1794414209159344128/Yb-3KVbO_400x400.jpg",
        description: "NUBIT : Computing has undergone a remarkable evolution over the past decades. Initially, it was characterized by proprietary applications running on individual local machines, which limited accessibility and collaboration.",
        link: "https://docs.nubit.org/nubit-da/developer-guide/run-a-node/run-a-node",
    },
    {
        img: "https://top3.net/custom/domain_1/image_files/ckeditor/sitemgr_1.png",
        description: "Dymension uses Tendermint which relies on a distributed set of validators that are responsible for committing and signing new blocks in the blockchain.",
        link: "https://docs.dymension.xyz/validate/dymension/config/",
    }
    
];

const contactConfig = {
    YOUR_EMAIL: "anggadaalif@gmail.com",
    YOUR_FONE: "(N/A)",
    description: "Feel free to reach out to me for collaborations, node setup, or any blockchain-related inquiries.",
    YOUR_SERVICE_ID: "service_xxx",
    YOUR_TEMPLATE_ID: "template_xxx",
    YOUR_USER_ID: "user_xxx",
};

const socialprofils = {
    github: "https://github.com/aliffahrijal24",
    facebook: "https://facebook.com/alif.fahrijal.5",
    instagram: "https://instagram.com/aliffahrizal05",
    twitter: "https://twitter.com/aliffahrijal4",
};

export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
